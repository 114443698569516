import * as React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import { PlayOutline, StarOutline, InformationCircleOutline } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import EndCheckout from '../../components/EndCheckout.js'
import Footer from '../../components/Footer.js'
import Logo from '../../components/Logo.js'
import { CheckoutContext } from "../../context/CheckoutContext"
import ReactPlayer from 'react-player'



const Stem = (props) => {
    return <article class="box">

        <div className="columns is-mobile content">
            <div className="column is-narrow">
                <p className="image is-96x96 stem-image">
                    <img src={props.image} alt={props.title} class="is-rounded" />
                </p>
            </div>
            <div className="column">
                <p className="title is-5">{props.title}</p>
                <p class="subtitle is-6">{props.native}</p>
                <p>{props.description}</p>
            </div>
        </div>




        {/* <hr /><div className="content">



                    <p class="title is-6">Aprūpe</p>
                    <p>{props.care}</p>

        </div> */}



    </article>
}

const Video = ({ url, captions }) => {
    return (<ReactPlayer
        url={url}
        width='100%'
        height='100%'
        // muted={true}
        config={{
            file: {
                attributes: {
                    controls: true,
                },
            },
            tracks: [
                { kind: 'captions', src: captions, label: "Instrukcijas", srcLang: 'lv', default: true }
            ]
        }}

    />)
}


// markup
const Hi = () => {

    const flowers = [

        
        {
            title: "Roze",
            image: "https://i.imgur.com/v35ns2pb.jpg",
            native: "Āzija",
            description: "Šoreiz izvēlējāmies divu krāsu rozes, kuru krāsa izcili papildina kompozīcijas toņus. Vai zināji, ka pasaulē ir vairāk par 25 000 rožu šķirņu?",
        },

        {
            title: "Āzijas gundega jeb Ranunculus",
            image: "https://i.imgur.com/ZiZdn58b.jpg",
            native: "Āzija",
            description: "Šoreiz izvēlējāmies koši dzeltenās krāsas Ranunculus Cloony Mix šķirnes ziedus. Vāzē tās var dzīvot līdz pat 10-12 dienām. Tuvākajās dienās tās vēl atvērsies un paliks 2x lielākas kā saņemšanas brīdī",
        },

        {
            title: "Krūmroze",
            image: "https://i.imgur.com/GNaV8mUb.jpg",
            native: "Āzija",
            description: "Viens no daudzajiem rožu veidiem. Daudzi mazāki ziedi uz viena stublāja, lieliski piešķir krāsu ziedu kompozīcijām. Ar mazo izmēru tās lieliski papildina lielākus ziedus. To krūmi var sasniegt pat divu metru augstumu.",
            care: "Ik pēc 2 dienām, kad maini ūdeni vāzē, nogriez tām kātiņus aptuveni 2 cm no apakšas, 45 grādu leņķī, lai zieds varētu uzsūkt vajadzīgo ūdeni un tajā esošās barības vielas. Rozes ilgst līdz vienai nedēļai.",
        },
        {
            title: "Anemone",
            image: "https://i.imgur.com/0HWOWerb.jpg",
            native: "Vidusjūras apgabals",
            description: "Kaprīzas, bet ļoti izteiksmīgas. Pieminētas pat sengrieķu stāstos un citās valstīs tiek sauktas par vēja puķēm. Līdzīgi kā tulpes, tās turpina augt vāzē, tāpēc ap tām jāatstāj brīva vieta. Anemones ir skaistas, bet to mūžs vāzē nav ilgs 3-6 dienas.",
            care: "Griez stublājus 45 grādu lenķī. Izmanto istabas temperatūras ūdeni un ziedu barību. Maini ūdeni ik pēc 2 dienām. Īpaši sargi no saules un siltuma avotiem. Neturi blakus augļiem. ",
        },

            {
                title: "Pistāciju lapas",
                image: "https://i.imgur.com/3oYQgBmb.jpg",
                native: "Centrālāzija un Tuvie Austrumi",
                description: "Pistāciju koku ģints ne tikai dod gardos riekstus, bet arī citus produktus, tostarp skaistus dekoratīvus zarus, kuru lapas lieliski papildina ziedu kompozīcijas.",
            },

            
    ]
    return (
        <main class="">


            <Helmet title="Instrukcija un atsauksmes" defer={false} />

            <section class="section">

                <div class="columns is-mobile">
                    <div class="column pt-0">
                        <Logo />
                    </div>
                    <div class="column is-one-third pt-0">
                        <EndCheckout />
                    </div>
                </div>
                <hr />


                <div className="container content">


                    <h1 class="title is-3 has-text-centered">👋 Tavi ziedi ir klāt!</h1>


                    {/* <p className="title is-3">Ko darīt tālāk?</p> */}

                    <div class="columns  has-text-centered">
                        <div class="column">
                            <PlayOutline height="22px" width="22px" />
                            <p>Noskaties īso instrukciju video</p>
                        </div>
                        <div class="column">
                            <InformationCircleOutline height="22px" width="22px" />
                            <p>Iepazīsties ar padomiem, lai paildzinātu prieku par ziediem</p>
                        </div>
                        <div class="column">
                            <StarOutline height="22px" width="22px" />
                            <p>Novērtē saņemtos ziedus un padalies ar draugiem</p>
                            <p>
                                <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-outlined">Aizpildīt novērtējumu</a>

                            </p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="section" style={{ backgroundColor: '#4a4a4a' }}>
                <div className="container content">


                    <Video
                        url="https://vz-da54803c-c33.b-cdn.net/b424f706-6af1-4cc7-93e4-2d77293d3824/playlist.m3u8"
                        captions="/titles/008.vtt"
                    />
                </div>
            </section>


            <section className="section">
                <div className="container content">

                    <div className="columns">
                        <div className="column">


                            <h2 class="title is-3">Rozā sapnis</h2>

                            <p>Nespējām noturēties pretī rozā Ranunculus burvībai, un beigās ziedu kompozīcijas krāsa ir dažādu toņu rozā. Vai zināji, ka floristi gandrīz vienmēr ziedu pušķos izvēlas baltas krāsas ziedus? Tas tādēļ, lai uz baltās krāsas fona pārējās ziedu krāsas izskatītos košākas.</p>
                                
                            <p>Šoreiz mazliet citādāka ziedu kārtošanas metode. Iedomājies 3 apļus, kur vidu ir mazs aplis, tad vidū lielāks un ārpusē vislielākais. Kompozīciju sākam veidot no vāzes malām (lielākais aplis), tad pārvirzamies uz vidējo apli un visbeidzot mazo apli.</p>

                            <p>Izvēlies vāzi ar platāku kaklu, lai kompozīcija nebūtu saspiesta</p>

                            <p>Ķeramies pie darba:</p>

                            <ol>
                                <li>Vāzē lej ūdeni istabas temperatūrā </li>
                                <li>Pievieno ziedu barību, 1 paciņa uz 0,5l ūdens, samaisi</li>
                                <li>Obligāti, visiem ziedu stublājiem nogriez 2cm no apakšas, lai tie spētu uzsūkt ūdeni un ilgāk paliktu svaigi</li>

                                <li>Sākam ar zaļajām pistāciju lapām, ko izkārtojam pa vāzes perimetru, izmantojam apmēram pusi no visiem zaļumiem. Ņem vērā vāzes ietilpību, iespējams, ka nemaz visus zaļumus nevajadzēs</li>
                                <li>1. aplis. Sākam ar arējo lielo apli. Izkārtojam baltās rozes pa perimetru gar vāzes malām</li>
                                <li>Tālāk ņemam rozā Ranunculus un izkārtojam tos starp baltajām rozēm. Pirmais aplis ir galā</li>
                                <li>Dažus no pistāciju lapu zarus liekam kā atdalošo joslu starp ārējo un vidējo apli (2-3 zariņi)</li>
                                <li>2. aplis. Vidējais aplis sastāvēs tikai no rozā krūmrozēm, visas izkārto pa perimetru. Ievēroji, cik līdzīga krāsa ir Ranunculus un krūmrozēm :) Tas arī ir noslēdz vidējo apli.</li>
                                <li>3. aplis. Visbeidzot mazākais aplis, kompozīcijas centrā liec pistāciju lapas, ja tās vēl palikušas, tad izkārto maigi rozā rozes</li>
                                <li>Pašā centrā izkārto baltās anemones. Atstāj tām brīvāku vietu, lai tās spēj plašāk izplaukt.</li>
                            </ol>

                            <p>Kā patīk šīs nedēļas kompozīcija? Atsūti foto Instagram!</p>


                        </div>
                        <div className="column">

                            <img src="https://i.imgur.com/NCJApZYh.jpg" alt="Nedēļas pušķis"/>

                            <h2 class="title is-34">Aprūpe</h2>

                            <p>Īpaši labi ziedi stāvēs, ja ūdeni tiem mainīsi reizi 2 dienās. Kā to pareizi darīt? </p>

                            <ol>
                                <li>Noskalo ziedu kātus</li>
                                <li>Izmazgā vāzi</li>
                                <li>Iepildi istabas temperatūras ūdeni, tajā izšķīdinot ziedu barību</li>
                                <li>Ziediem nogriez 3cm no stublāju apakšas, ar asām šķērēm griežot slīpi, 45° lenķī</li>
                            </ol>

                            <h3 class="title is-4">Par ziedu barību</h3>

                            <p>Ziedu barībai ir svarīgi ievērot norādīto barības-ūdens attiecību, lai tā nav par stipru vai vāju. Ja tā būs par koncentrētu, tad ziedi var apdegt, ja  koncentrācija par vāju, tad ziediem pietrūkt vajadzīgās barības vielas un tie ātrāk novecos. Mūsu līdzi dotā barības vielas paciņa, tiek šķīdināta 0,5l ūdens</p>

                        </div>
                    </div>




                </div>
            </section>




            <section className="section" style={{ backgroundColor: '#F0E2D7' }}>
                <div className="container content">

                    <p className="title is-3">Kādus ziedus saņēmi?</p>

                    <div className="columns is-multiline">
                        {flowers.map(f => {
                            return (
                                <div className="column is-one-third">
                                    <Stem
                                        title={f.title}
                                        latin={f.latin}
                                        image={f.image}
                                        native={f.native}
                                        care={f.care}
                                        description={f.description}
                                    />

                                </div>
                            )
                        })}


                    </div>


                </div>
            </section>


            <section className="section content">
                <div className="container">

                    <div className="columns">
                        <div className="column">

                            <h2 class="title is-3">Novērtējums</h2>

                            <p>Mēs priecāsimies, ja atstāsi godīgu novērtējumu par Heyday pakalpojumu.</p>
                            <p>Tas aizņems tikai 2 minūtes.</p>

                            <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-primary">Aizpildīt anketu</a>


                        </div>
                        <div className="column">

                            <h2 class="title is-3">Pasaki par mums saviem draugiem</h2>

                            <p>Ja Tev saņemtie ziedi patika, padalies ar Heyday saviem draugiem. Jo vairāk cilvēki pievienojas mūsu lokam, jo plašāk varēsim sniegt ziedu prieku.</p>

                            <p>
                                <a class="button is-success" href="whatsapp://send?text=Heyday ziedu abonements https://heyday.lv?utm_source%3Dshare%26utm_medium%3Dwhatsapp%26utm_campaign%3Dfromhi" data-action="share/whatsapp/share">Pārsūtīt saiti Whatsapp</a>
                            </p>


                        </div>
                    </div>





                </div>


            </section>

            <Footer />



        </main>
    )
}

export default Hi
